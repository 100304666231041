import * as d3 from 'd3';
import Data from '../resources/projects.json';

const WIDTH = 600;
const HEIGHT = 600;
const PERC = 0.75;
let svgHeight;
let svgWidth;

function run() {
  //   var svgList = d3.selectAll(".tree_arch");
  //   console.log("TCL: run -> var svgList", svgList);
  const list = document.querySelectorAll('.tree_arch');
  list.forEach((element, index) => {
    D3ForceBuilder(d3.select(element), index).updateSimulation();
  });
}

run();

function D3ForceBuilder(svg, index) {
  const self = {};

  self.nodes = [...Data[index].treeNode.nodes];
  self.links = [...Data[index].treeNode.links];

  self.linkElements = null;
  self.nodeElements = null;
  self.textElements = null;

  try {
    const parentTemp = document.querySelector('.content');
    svgHeight = parentTemp.offsetHeight * PERC;
    svgWidth = parentTemp.offsetWidth * PERC;
  } catch (error) {
    svgHeight = HEIGHT;
    svgWidth = WIDTH;
  }

  // document.get;
  svg.attr('width', svgWidth).attr('height', svgHeight);

  // we use svg groups to logically group the elements together
  self.linkGroup = svg.append('g').attr('class', 'links');
  self.nodeGroup = svg.append('g').attr('class', 'nodes');
  self.textGroup = svg.append('g').attr('class', 'texts');

  self.linkForce = d3
    .forceLink()
    .id((link) => link.id)
    .strength((link) => link.strength);

  self.simulation = d3
    .forceSimulation()
    .force('link', self.linkForce)
    .force('charge', d3.forceManyBody().strength(-120))
    .force('center', d3.forceCenter(svgWidth / 2, svgHeight / 2));
  self.dragDrop = d3
    .drag()
    .on('start', (node) => {
      node.fx = node.x;
      node.fy = node.y;
    })
    .on('drag', (node) => {
      self.simulation.alphaTarget(0.7).restart();
      node.fx = d3.event.x;
      node.fy = d3.event.y;
    })
    .on('end', (node) => {
      if (!d3.event.active) {
        self.simulation.alphaTarget(0);
      }
      node.fx = null;
      node.fy = null;
    });

  self.updateGraph = () => {
    // links
    self.linkElements = self.linkGroup.selectAll('line').data(self.links, (link) => link.target.id + link.source.id);
    self.linkElements.exit().remove();
    self.linkEnter = self.linkElements
      .enter()
      .append('line')
      .attr('stroke-width', 1)
      .attr('stroke', 'rgba(255, 255, 255, 0.116)');
    self.linkElements = self.linkEnter.merge(self.linkElements);
    // nodes
    self.nodeElements = self.nodeGroup.selectAll('circle').data(self.nodes, (node) => node.id);
    self.nodeElements.exit().remove();
    self.nodeEnter = self.nodeElements
      .enter()
      .append('circle')
      .attr('r', (node) => node.size)
      .attr('class', () => 'nav__link')
      .call(self.dragDrop);

    self.nodeElements = self.nodeEnter.merge(self.nodeElements);
    // texts
    self.textElements = self.textGroup.selectAll('text').data(self.nodes, (node) => node.id);
    self.textElements.exit().remove();
    self.textEnter = self.textElements
      .enter()
      .append('text')
      .text((node) => node.label)
      .attr('font-size', 15)
      //   .attr("dx", 15)
      .attr('dy', 4);
    self.textElements = self.textEnter.merge(self.textElements);
  };

  self.updateSimulation = () => {
    self.updateGraph();
    self.simulation.nodes(self.nodes).on('tick', () => {
      self.nodeElements.attr('cx', (node) => node.x).attr('cy', (node) => node.y);
      self.textElements.attr('x', (node) => node.x).attr('y', (node) => node.y);
      self.linkElements
        .attr('x1', (link) => link.source.x)
        .attr('y1', (link) => link.source.y)
        .attr('x2', (link) => link.target.x)
        .attr('y2', (link) => link.target.y);
    });
    self.simulation.force('link').links(self.links);
    self.simulation.alphaTarget(0.7).restart();
  };

  return self;
}
